import { firestore } from "../firebase";

const queriesRef = firestore.collection("queries");
export const queryApi = {
  post: async (query) => {
    try {
      const res = await queriesRef.add(query);
      return res.id;
    } catch (error) {
      console.log(error);
    }
  },
};

const subscribersRef = firestore.collection("subscribers");
export const subscribersApi = {
  post: async (subscriber) => {
    try {
      const res = await subscribersRef.add(subscriber);
      return res.id;
    } catch (error) {
      console.log(error);
    }
  },
};
