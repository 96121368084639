import React, { Fragment, useState } from "react";
import logo from "../../../images/logo-80.png";
import LoginSignUp from "../../../components/LoginSignUp/LoginSignUp";
import { firebase } from "../../../firebase";
import { Link } from "react-router-dom";
import Modal from "react-modal";
const customStyles = {
  content: {
    padding: 0,
    height: "100%",
    width: "100%",
    margin: 0,
    inset: 0,
    paddingTop: "5rem",
  },
};

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [loginOrSignUp, setLoginOrSignUp] = useState("login");
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [userName, setUserName] = useState("");

  const onLoginSignUpClick = (label) => {
    setLoginOrSignUp(label);
    if (!open) {
      setOpen(true);
      setMenuOpen(false);
    }
    // setOpen(!open);
  };

  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      setLoggedInUser(user);
      user.displayName !== ""
        ? setUserName(user.displayName)
        : setUserName(user.email);
      setOpen(false);
    } else {
      setLoggedInUser(null);
    }
  });

  const logOff = () => {
    firebase.auth().signOut();
  };

  const pages = [
    // { to: "/", label: "Home" },
    // { to: "/product-stocks-analysis", label: "Product Analysis" },
    // { to: "/blog", label: "Blog" },
    // { to: "/portfolio", label: "Portfolio" },
  ];

  return (
    <div className="header flex bg-white h-14">
      {<LoginSignUp isOpen={open} label={loginOrSignUp} open={setOpen} />}
      <nav
        className="top-0 fixed left-0 right-0 z-30 h-14 flex bg-white"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="flex-shrink-0 flex items-center p-8">
          <a
            className="cursor-pointer flex items-center flex-grow-0 flex-shrink-0 py-2"
            href="/"
          >
            <img src={logo} width="45" height="45" alt="unipro advisory" />
            <span className="font-bold text-2xl pl-2 text-blue-400">
              unipro
            </span>
            <span className="font-bold text-2xl text-blue-300">advisory</span>
          </a>
        </div>
        <div id="navbarBasicExample" className="flex justify-between flex-1">
          <div className="flex">
            {pages.map((page) => (
              <Link
                to={page.to}
                className="cursor-pointer flex items-center flex-grow-0 flex-shrink-0 py-2 px-3 hover:text-blue-400 text-xs font-bold uppercase"
              >
                {page.label}
              </Link>
            ))}
          </div>
          <div className="flex hidden lg:block md:block">
            <div className="buttons flex items-center px-4 m-2">
              {!loggedInUser && (
                <Fragment>
                  <button
                    className="inline-flex text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded text-lg"
                    onClick={() => onLoginSignUpClick("login")}
                  >
                    Log in
                  </button>
                  <button
                    className="inline-flex text-gray-700 bg-gray-200 border-0 py-2 px-6 focus:outline-none hover:bg-gray-300 rounded text-lg ml-2"
                    onClick={() => onLoginSignUpClick("signup")}
                  >
                    <strong>Sign up</strong>
                  </button>
                </Fragment>
              )}
              {loggedInUser && (
                <div className="flex items-center">
                  {loggedInUser.photoURL && (
                    <div className="h-10 w-10 flex flex-shrink-0 mx-4">
                      <img
                        src={loggedInUser.photoURL}
                        alt="Avatar"
                        class=" rounded-full"
                      ></img>
                    </div>
                  )}
                  <div className="text-sm font-bold">{`${userName}`}</div>
                  <button
                    className="inline-flex text-gray-700 bg-gray-200 border-0 py-2 px-6 focus:outline-none hover:bg-gray-300 rounded text-lg ml-2"
                    onClick={logOff}
                  >
                    Sign out
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div class="block lg:hidden md:hidden m-2">
          <span
            class="flex items-center px-3 py-2 border rounded"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            {!menuOpen && (
              <svg
                class="h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            )}
            {menuOpen && (
              <span
                className="modal-close h-3 w-3 flex justify-center items-center"
                aria-label="close"
              >
                ✕
              </span>
            )}
          </span>
        </div>
      </nav>
      <Modal style={customStyles} isOpen={menuOpen}>
        <div className="buttons flex items-center px-4">
          {!loggedInUser && (
            <Fragment>
              <button
                className="inline-flex text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded text-lg"
                onClick={() => onLoginSignUpClick("login")}
              >
                Log in
              </button>
              <button
                className="inline-flex text-gray-700 bg-gray-200 border-0 py-2 px-6 focus:outline-none hover:bg-gray-300 rounded text-lg ml-2"
                onClick={() => onLoginSignUpClick("signup")}
              >
                <strong>Sign up</strong>
              </button>
            </Fragment>
          )}
          {loggedInUser && (
            <div className="flex items-center">
              {loggedInUser.photoURL && (
                <div className="h-10 w-10 flex flex-shrink-0 mx-4">
                  <img
                    src={loggedInUser.photoURL}
                    alt="Avatar"
                    class=" rounded-full"
                  ></img>
                </div>
              )}
              <div className="text-sm font-bold">{`${userName}`}</div>
              <button
                className="inline-flex text-gray-700 bg-gray-200 border-0 py-2 px-6 focus:outline-none hover:bg-gray-300 rounded text-lg ml-2"
                onClick={logOff}
              >
                Sign out
              </button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Header;
