import classNames from "classnames";
import React from "react";
import { useForm } from "react-hook-form";

export default function Subscribe({ setSubcriber }) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    setSubcriber(data);
    reset({ email: "" });
  };

  const getValidationCssClass = (field) => {
    return classNames(
      "w-full bg-white rounded border text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out",
      {
        "border-red-500 focus:border-red-500 focus:ring-2 focus:ring-red-200":
          field,
      },
      {
        "border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200":
          !field,
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div class="flex w-full justify-left items-end flex-wrap pt-12">
        <div className="w-full">
          <label for="email" class="leading-7 text-md text-gray-600 inline">
            Weekly newsletter
          </label>
        </div>
        <div className="w-full flex flex-row">
          <div class="relative mr-4 lg:w-full xl:w-1/2 w-2/4 md:w-full text-left">
            <input
              type="email"
              {...register("email", { required: true })}
              id="email"
              name="email"
              placeholder="Your Email"
              className={getValidationCssClass(errors.email)}
            />
          </div>
          <button
            type="submit"
            class="inline-flex text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded text-lg"
          >
            Subscribe
          </button>
        </div>
      </div>
    </form>
  );
}
