import React from "react";
import Home from "../Home/Home";
import Header from "./Header";
// import Header from "../../components/Header";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import Blog from '../Blog';
// import Portfolio from '../Portfolio';
// import StockAnalysis from '../StockAnalysis';

const App = () => {
  return (
    <div className="app">
      <Router>
        <Header title={"unipro advisory"} />
        {/* <Header /> */}
        <Switch>
          {/* <Route path="/blog">
            <Blog />
          </Route>
          <Route path="/portfolio">
            <Portfolio />
          </Route>
          <Route path="/product-stocks-analysis">
            <StockAnalysis />
          </Route> */}
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

export default App;
