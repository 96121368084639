const TEXT_DATA = {
  clientTestimonial: [
    {
      name: "Jitendra Parmar",
      profession: "",
      content:
        "Unipro is a different advisory which I have seen ever, I have tried two advisories before taking service from unipro but my experience was not so good as u was getting an average return of around 12-15%, after taking service from unipro my portfolio was rocking and got average 60-70% return in momentum calls and multi-bagger calls are also rocking, in most of the momentum calls I got 1x-2x return in 3-4 months when I took service from unipro my portfolio value was 10 lakh, after two years I build my portfolio worth 65 lakh by positive approach from unipro, in short, I m very much satisfied with quality calls from unipro, also they are guiding in a well manner about allocation per script enables us to get maximum return. I will never quit service of unipro.",
    },
    {
      name: "Akhil Malhotra",
      profession: "Chartered Accountant",
      content:
        "Unipro advisory is an excellent equity research advisory service; I am pleased to see the results in almost every recommendation. I get the feeling that Unipro advisory is always ahead of the game that what only matters the most in the stock market. I highly recommend their service because of their proactive approach in finding the right opportunities at right time.",
    },
    {
      name: "Devan Ghoghari",
      profession: "",
      content:
        "I am very happy with the recommendations given by Unipro Advisory. I am very much impressed by the in-depth knowledge that they possess in identifying shares at a very nascent stage. Wishing you all the best.",
    },
    {
      name: "Siva Prasad",
      profession: "",
      content:
        "I appreciate your recommendations, I never received this type of recommendation in this market.I congratulate you to achieve 100%. ",
    },
    {
      name: "Ranganath",
      content:
        "My association and journey of 6 months with Unipro are fabulous. The products and services offered by Unipro are amazing and commendable. It is not just the monetary benefit of compounding but it does also with Dedication and Transparency of you, which helps the customer to be at ease and trustworthy. I always recommend my near and dear to be associated with Unipro and create wealth. Thanking you. Best Regards Ranganath",
      profession: "",
    },
    {
      name: "Sandeep Puri.",
      profession: "",
      content:
        "I have been investing in the stock market since 2002. I was in the loss in many stocks up to 2019. There was no real gain as far as the overall portfolio is concerned. Then came in touch with Unipro advisory. From October 2019 till date(June 2021), I am in a very good profit now. Unipro advisory was bullish when everyone was bearish in the Market. All of the recommendations were superb and moved quickly. Even a long-term portfolio is doing perfectly. Feeling excited because never have such type of profit inequities in a journey of about 20 years. Regards and Thanks to Unipro for a part of my life now.",
    },
    {
      name: "L V RAMANA",
      profession: "",
      content:
        "My gut feeling says Unipro advisory services is my best analyst in the stock market. I received truly excellent calls which have given more than 100% returns in a short span of my subscription. I can say we can follow them with 100% confidence.",
    },
    {
      name: "",
      profession: "",
      content:
        "Precise stock recommendations by Unipro are amazing and keeps one focused. Where there is constant noise around you in markets.",
    },
    {
      name: "",
      profession: "",
      content:
        "Your potential multi-bagger identifications have been very good. The choice of momentum stocks is also very good. Your association has been very lucrative and profitable to me so far. Great going.",
    },
    {
      name: "",
      profession: "",
      content:
        "Unipro Advisory under Mohit Kumar has been recommending stocks that have greatly benefited me with gains even immediately. Very timely disposal and repeat recommendations in some cases also been beneficial. Repeat recommendations help in adding and thereby increasing the holding of exclusive stocks. Sincerely wishing others to try him out and benefit themselves as sure-shot winners. Wishing him the best for his progress and many more members benefiting. 🥂",
    },
    {
      name: "",
      profession: "",
      content:
        "Unipro Advisory is an excellent providing stock marketing analysis services. I have been joined with them since last more than 2 years and I have earned handsome profits. Excellent performance, I am sure the same performances will continue in the future. 👍",
    },
    {
      name: "Gurudas Aras",
      content:
        "I am a subscriber to Unipro Advisory for nearly 9 months now. In this period I got immensely benefited from their timely advice on the right stocks. The success rate being very high, the retail investors following Unipro's advice are always a mile ahead of others. Most of the stocks recommended are not only safe but are also sure-shot multi-baggers. ",
      profession: "",
    },
    {
      name: "",
      content:
        "Excellent stock selections. More than 90% of recommendations gave more than 50% return in a very short time. Great to have such an advisory. Picking the right stock well before other's identification. All the very best to Unipro.",
      profession: "",
    },
  ],
  products: [
    {
      id: 100,
      title: "Momentum 5 Portfolio",
      objective: "Short-Term capital appreciation.",
      description:
        "Start with our free Momentum 5 Portfolio with upto 5 Small-Cap & Mid-Cap stocks.",
    },
    {
      id: 101,
      title: "Momentum 20 Portfolio",
      objective: "Short-Term capital appreciation.",
      description:
        "Investing in Small-Cap & Mid-Cap Companies With a systematic investment approach, the Portfolio is designed to focus on momentum - the phenomenon that stocks that have performed well in the past relative to other stocks (winners) continue to perform well in the future, and stocks that have performed relatively poorly (losers) continue to perform poorly.",
      approachList: [
        "The Portfolio consists of 20-30 small-cap & mid-cap companies with positive momentum. A stock is considered to have positive momentum if it has performed well in the prior 12 months relative to other stocks in the NSE universe.",
        "We apply a  systematic approach to construct the portfolio that starts by identifying the NSE universe, ranks the stocks in the universe by their total return over the prior 12 months excluding the last month, selects those that rank in the top third, weights them by market capitalization, and rebalances at least quarterly.",
        "Limits stocks available for investment to the bottom 400 – 500 out of our NSE stock universe.",
        "Enters and exits the market based on signals generated by our System.",
      ],
      riskModals: [
        "On sell, the strategy is in cash.  On buy, it is fully invested.",
        "If an individual stock falls more than 10..% from entry price, it is sold and replaced with the top-ranking stock for that week (stop loss) Stop losses is an important factor for risk management.",
      ],
      price: "30,000",
    },
    {
      id: 102,
      title: "Large Cap Momentum Portfolio",
      objective: "Seeks Low-Risk capital appreciation",
      description:
        "Investing in Continuing Price Trends of Indian Large Cap Companies through a systematic investment approach, the Product is designed to capture momentum - the phenomenon that stocks that have performed well in the past relative to other stocks (winners) continue to perform well in the future, and stocks that have performed relatively poorly (losers) continue to perform poorly.",
      approachList: [
        "The Portfolio consists of 20-30 Large-cap companies with positive momentum.",
        "Invests in stocks of large-cap with positive momentum. A stock is considered to have positive momentum if it has performed well in the prior 12 months relative to other stocks in the investment universe.",
        "We employ a systematic approach to construct the portfolio that starts by identifying the investment universe, ranks the stocks in the universe by their total return over the prior 12 months excluding the last month, selects those that rank in the top third, weights them by market capitalization, and rebalances at least quarterly.",
        "WE rank stocks according to momentum, capturing different signals including price, earnings announcement, and residual momentum. Stocks with positive momentum tend to outperform broad equity markets and growth style benchmarks over the long term.",
      ],
      riskModals: [
        "On sell, the strategy is in cash.  On buy, it is fully invested.",
        "If an individual stock falls more than 13.5% from the entry price, it is sold and replaced with the top-ranking stock for that week (stop loss). Our stop losses provide an additional layer of risk management.",
      ],
      price: "20,000",
    },
    {
      id: 103,
      title: "MICRO-CAP  MOMENTUM PORTFOLIO",
      objective: "Mid Term to long-term capital appreciation.",
      description:
        "Investing in  micro-cap  Companies (Market capitalization <= 1000 cr ) With a systematic investment approach, the Portfolio is designed to focus on momentum - the phenomenon that stocks that have performed well in the past relative to other stocks (winners) continue to perform well in the future, and stocks that have performed relatively poorly (losers) continue to perform poorly.",
      approachList: [
        "The Portfolio consists of 10-12  micro-cap companies with positive momentum. A stock is considered to have positive momentum if it has performed well in the prior 12 months relative to other stocks in the NSE universe.",
        "We apply a  systematic approach to construct the portfolio that starts by identifying the NSE universe, ranks the stocks in the universe by their total return over the prior 12 months excluding the last month, selects those that rank in the top third, weights them by market capitalization, limits stocks available for investment to the bottom 100-200 out of our NSE-BSE stock universe.",
        "Buy & Hold Approach for max gain",
      ],
      riskModals: [
        "If an individual stock falls more than 10-15..% from entry price, it is sold and replaced with the top-ranking stock for that month  (stop loss) Stop losses is an important factor for risk management.",
        "Expected return 100% in a year ",
      ],
      why: [
        {
          q: "Why Invest in the Our MICRO-CAP Momentum Portfolio?",
          a: "Micro-Caps are where the inefficiencies are. All great company starts small",
        },
      ],
      price: "20,000",
    },
  ],
};

export default TEXT_DATA;
