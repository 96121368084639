import React from "react";
import clientImage from "../../images/clients.jpg";

export default function Statistics() {
  return (
    <section class="text-gray-600 body-font">
      <div class="container px-5  mx-auto flex flex-wrap">
        <div class="flex flex-wrap -mx-4 mt-auto mb-auto lg:w-1/2 sm:w-2/3 content-start sm:pr-10">
          <div class="w-full sm:p-3 px-4 mb-6">
            <h1 class="title-font text-3xl font-bold  mb-2 text-gray-800 text-center hidden">
              Volume speaks
            </h1>
            <div class="leading-relaxed text-xl sm:text-2xl md:text-3xl lg:text-3xl text-center">
              Our privilege clients and subscribers
            </div>
          </div>
          <div class="p-4 sm:w-1/2 lg:w-1/3 w-full flex flex-col items-center justify-items-center">
            <h2 class="title-font font-medium text-3xl text-gray-900">2.1K</h2>
            <p class="leading-relaxed">Premium Users</p>
          </div>
          <div class="p-4 sm:w-1/3 lg:w-1/3 w-full flex flex-col items-center justify-items-center">
            <h2 class="title-font font-medium text-3xl text-gray-900">4.8K</h2>
            <p class="leading-relaxed">Subscribes</p>
          </div>
          <div class="p-4 sm:w-1/3 lg:w-1/3 w-full flex flex-col items-center justify-items-center">
            <h2 class="title-font font-medium text-3xl text-gray-900">4</h2>
            <p class="leading-relaxed">Products</p>
          </div>
        </div>
        <div class="lg:w-1/2 sm:w-1/3 w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
          <img
            class="object-cover object-center w-full h-full"
            src={clientImage}
            alt="stats"
          />
        </div>
      </div>
    </section>
  );
}
