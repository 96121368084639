import React from "react";
import { useForm } from "react-hook-form";
import classnames from "classnames";

export default function Feedback({ setQuery }) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    setQuery(data);
    reset({ name: "", email: "", message: "" });
  };

  const getValidationCssClass = (field) => {
    return classnames(
      "w-full bg-white rounded border text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out",
      {
        "border-red-500 focus:border-red-500 focus:ring-2 focus:ring-red-200":
          field,
      },
      {
        "border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-200":
          !field,
      }
    );
  };

  return (
    <div>
      <section class="text-gray-600 body-font relative">
        <div class="container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap">
          <div class="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
            <iframe
              width="100%"
              height="100%"
              class="absolute inset-0"
              frameborder="0"
              title="map"
              marginHeight="0"
              marginWidth="0"
              scrolling="no"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.583128944475!2d77.67187301524044!3d12.870180020576903!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae6cb7818d633f%3A0xed6a09ec3f209121!2s49%2C%20CoconutGarden%2C%20Rayasandra%2C%20Bengaluru%2C%20Karnataka%20560100!5e0!3m2!1sen!2sin!4v1623484595918!5m2!1sen!2sin"
              style={{ filter: "grayscale(1) contrast(1.2) opacity(0.7)" }}
            />
            <div class="bg-white relative flex flex-wrap py-6 rounded shadow-md">
              <div class="lg:w-1/2 px-6">
                <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">
                  ADDRESS
                </h2>
                <p class="mt-1">
                  #49, 4th Main, Coconut Garden, Are Bannerghatta Main Road
                  Bangalore KA 560076 India.
                </p>
              </div>
              <div class="lg:w-1/2 px-6 mt-4 lg:mt-0">
                <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">
                  EMAIL
                </h2>
                <a class="text-blue-500 leading-relaxed" href="_blank">
                  info@uniproadvisory.com
                </a>
                <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">
                  PHONE
                </h2>
                <p class="leading-relaxed">+91 9066592894</p>
              </div>
            </div>
          </div>

          <div class="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
            <form onSubmit={handleSubmit(onSubmit)}>
              <h2 class="text-gray-900 text-lg mb-1 font-medium title-font">
                Contact Us
              </h2>
              <div class="relative mb-4">
                <label for="name" class="leading-7 text-sm text-gray-600">
                  Name
                </label>
                <div className="flex">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    {...register("name", { required: true })}
                    className={getValidationCssClass(errors.name)}
                  />
                </div>
              </div>
              <div class="relative mb-4">
                <label for="email" class="leading-7 text-sm text-gray-600">
                  Email
                </label>
                <input
                  {...register("email", { required: true })}
                  type="email"
                  id="email"
                  name="email"
                  className={getValidationCssClass(errors.email)}
                />
              </div>
              <div class="relative mb-4">
                <label for="message" class="leading-7 text-sm text-gray-600">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  {...register("message", { required: true })}
                  className={getValidationCssClass(errors.message)}
                ></textarea>
              </div>
              <button
                type="submit"
                class="text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded text-lg"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}
