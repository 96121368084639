import React from "react";
import claasnames from "classnames";
import "./LoginSignUp.style.scss";
import { firebase } from "../../firebase";
import { GoogleLoginButton } from "react-social-login-buttons";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
  },
};

const LoginSignUp = ({ isOpen, label, open }) => {
  const provider = new firebase.auth.GoogleAuthProvider();

  const onLogin = async () => {
    try {
      const result = await firebase.auth().signInWithPopup(provider);
      // const credential = result.credential;
      // const token = credential.accessToken;
      const user = result.user;
      console.log({ user });
    } catch (error) {
      const errorCode = error.code;
      // const errorMessage = error.message;
      // const email = error.email;
      // const credential = error.credential;
      console.log({ errorCode });
    }
  };

  const btnLabel = label === "login" ? `Login with` : `Sign up with`;

  return (
    <Modal isOpen={isOpen} style={customStyles}>
      <div className="login-sign-up">
        <div className={claasnames("modal", { "is-active": isOpen })}>
          <div className="modal-content card">
            <div className="l-container">
              <div className="unipro">
                UNIPRO&nbsp;<span className="advisory">ADVISORY</span>
              </div>
              <div className="offer-label">
                Flat 10% off on all plan. Hurry!
              </div>
              <span className="offer-emoji">&#127881;&nbsp;&#127881;</span>
            </div>
            <div className="r-container">
              {/* <div className="cont ent-header">{`${btnLabel} Google`}</div> */}
              <div className="login-button">
                <GoogleLoginButton
                  onClick={onLogin}
                  text={`${btnLabel} Google`}
                />
              </div>
            </div>
          </div>
          <button
            className="modal-close text-2xl"
            aria-label="close"
            onClick={() => open(false)}
          >
            ✕
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default LoginSignUp;
