import React, { useEffect, useState, useRef } from "react";
import banner from "../../images/banner.jpg";
import { queryApi, subscribersApi } from "../../apis/api";
import Feedback from "./Feedback";
import Footer from "./Footer";
import Testimonial from "./Testimonial";
import Products from "./Products";
import Statistics from "./Statistics";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Subscribe from "./Subscribe";
import { firebase } from "../../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons";
const getCurrentDate = () => {
  return firebase.firestore.Timestamp.fromDate(new Date()).toDate();
};

const Home = () => {
  const [query, setQuery] = useState(null);
  const [subcriber, setSubcriber] = useState(null);
  const [join, setJoin] = useState(false);
  const contactRef = useRef();

  useEffect(() => {
    if (contactRef.current && join) {
      contactRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
      setJoin(false);
    }
  }, [join]);

  useEffect(() => {
    async function addQueries() {
      try {
        const createdAt = getCurrentDate();
        await queryApi.post({ ...query, createdAt });
        setQuery(null);
        toast.success("Successfully submitted!");
      } catch (error) {
        toast.error("Error occured!");
      }
    }
    if (query) {
      addQueries();
    }
  }, [query]);

  useEffect(() => {
    async function addSubscriber() {
      try {
        const createdAt = getCurrentDate();
        await subscribersApi.post({ ...subcriber, createdAt });
        setSubcriber(null);
        toast.success("Successfully subscribed!");
      } catch (error) {
        toast.error("Error occured!");
      }
    }
    if (subcriber) {
      addSubscriber();
    }
  }, [subcriber]);

  return (
    <div>
      <ToastContainer />
      <div className="flex flex-wrap pt-4 flex-1 w-100">
        <div className="xl:w-1/2">
          <img src={banner} width="900" height="280" alt="unipro advisory" />
        </div>
        <div className="xl:w-1/2 px-4">
          {/* {query.email && <div className="hidden">{query.email}</div>} */}
          <div className="text-blue-500 text-lg md:text-xl pt-4">
            <span className="inline-block pl-4">
              <FontAwesomeIcon icon={faPhoneAlt} />
              <span className="p-2">
                <a href="tel:+919066592894">+91 9066592894</a>
              </span>
            </span>
            <span className="px-4 inline-block">
              <FontAwesomeIcon icon={faEnvelope} />
              <span className="px-2">
                <a href="mailto:info@uniproadvisory.com">
                  info@uniproadvisory.com
                </a>
              </span>
            </span>
          </div>
          <div className="font-semibold text-lg xl:text-3xl md:text-2xl sm:text-lg pt-6">
            <b>Why you should invest in our Momentum Portfolio?</b>
          </div>
          <div className="sm:text-lg pt-4 md:pt-8 lg:pt-8  text-lg xl:2text-xl md:text-2xl text-gray-600">
            We use a systematic rules-based approach to select stocks for the
            portfolio. It ranks stocks according to momentum, capturing
            different signals including price, earnings announcement, and
            residual momentum. Stocks with positive momentum tend to outperform
            broad equity markets over the long term.
          </div>
          <Subscribe setSubcriber={setSubcriber} />
        </div>
      </div>
      <Products setJoin={setJoin} />
      <Statistics />
      <Testimonial />
      <div ref={contactRef}>
        <Feedback setQuery={setQuery} />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
