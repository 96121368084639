import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyCgLWdYZJsNMNveQ8NTBdMIh8wRVC75-6w",
  authDomain: "unipro-advisory.firebaseapp.com",
  projectId: "unipro-advisory",
  storageBucket: "unipro-advisory.appspot.com",
  messagingSenderId: "639414314946",
  appId: "1:639414314946:web:895fdaeae6f42340ff185b",
  measurementId: "G-Q2E3VBEP0Y",
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const firestore = firebase.firestore();

export { firebase, firestore };
