import React, { Fragment, useState } from "react";
import TEXT_DATA from "../../constant";
import whatsapp from "../../images/whatsapp-logo.png";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    border: 0,
    background: "transparent",
    overflowY: "auto",
  },
};

const ProductDetails = ({ product, setProduct }) => {
  return (
    <div class="w-full flex justify-center" style={{ height: "70vh" }}>
      <div class=" lg:w-1/2 p-6 rounded-lg bg-gray-50 flex flex-col relative overflow-scroll shadow-2xl">
        <h2 class="text-sm tracking-widest title-font mb-1 font-medium">
          {product.title}
        </h2>
        <h1 class="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200">
          <span>₹{product.price}</span>
          <span class="text-lg ml-1 font-normal text-gray-500">/year</span>
        </h1>
        <p>Objective</p>
        <p class="flex items-center text-gray-600 mb-2">{product.objective}</p>
        <p class="flex items-center text-gray-600 mb-2">
          {product.description}
        </p>
        <p>Our approach</p>
        {product.approachList.map((approach) => (
          <p class="flex items-center text-gray-600 mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2.5"
                class="w-3 h-3"
                viewBox="0 0 24 24"
              >
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>
            {approach}
          </p>
        ))}
        <p>Risk Modals</p>
        {product.riskModals.map((riskModal) => (
          <p class="flex items-center text-gray-600 mb-2">
            <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2.5"
                class="w-3 h-3"
                viewBox="0 0 24 24"
              >
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
            </span>
            {riskModal}
          </p>
        ))}
        <button
          className="modal-close text-2xl"
          aria-label="close"
          onClick={() => setProduct(null)}
          style={{ position: "absolute", top: 0, right: 0, padding: "0.75rem" }}
        >
          ✕
        </button>
      </div>
    </div>
  );
};

export default function Products({ setJoin }) {
  const products = TEXT_DATA.products;
  const [product, setProduct] = useState(null);
  return (
    <Fragment>
      <Modal isOpen={product !== null} style={customStyles}>
        <div className="scroll-component">
          <div className="scroll-content">
            {product && (
              <ProductDetails product={product} setProduct={setProduct} />
            )}
          </div>
        </div>
      </Modal>
      <section class="text-gray-600 body-font overflow-hidden">
        <div class="container px-5 py-24 mx-auto">
          <div class="flex flex-col text-center w-full mb-20">
            <h1 class="sm:text-4xl text-3xl font-medium title-font mb-2 text-gray-900">
              Products
            </h1>
            <p class="lg:w-2/3 mx-auto leading-relaxed text-2xl text-gray-500">
              Choose one of our momentum portfolio and bring your investment to
              the next level.
              <span
                class="text-blue-500 inline-flex items-center md:mb-2 lg:mb-0 text-xl cursor-pointer"
                onClick={() => setJoin(true)}
              >
                &nbsp;Join now
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </span>
            </p>
            <div class="flex items-center justify-center text-blue-400">
              <img src={whatsapp} width="45" height="45" alt="WhatsApp" />
              <a href="https://wa.link/kcvym3" target="_blank" rel="noreferrer">
                chat with us
              </a>
            </div>
          </div>
          <div class="flex flex-wrap -m-4">
            <div class="p-4 xl:w-1/4 md:w-1/2 w-full">
              <div class="h-full p-6 rounded-lg bg-gray-50  flex flex-col relative overflow-hidden shadow-xl">
                <h2 class="text-sm tracking-widest title-font mb-1 font-medium">
                  {products[0].title}
                </h2>
                <h1 class="text-5xl text-gray-900 pb-4 mb-4 border-b border-gray-200 leading-none">
                  Free
                </h1>
                <p class="flex items-center text-gray-600 mb-2">
                  {products[0].description}
                </p>
              </div>
            </div>
            <div class="p-4 xl:w-1/4 md:w-1/2 w-full">
              <div class="h-full p-6 rounded-lg bg-gray-50 flex flex-col relative overflow-hidden  shadow-lg">
                <span class="bg-gray-200 text-gray-800 font-bold  px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">
                  POPULAR
                </span>
                <h2 class="text-sm tracking-widest title-font mb-1 font-medium">
                  {products[1].title}
                </h2>
                <h1 class="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200">
                  <span>₹{products[1].price}</span>
                  <span class="text-lg ml-1 font-normal text-gray-500">
                    /year
                  </span>
                </h1>
                <p class="flex items-center text-gray-600 mb-2">
                  {products[1].description}
                </p>
                <span
                  class="text-blue-500 inline-flex items-center md:mb-2 lg:mb-0"
                  onClick={() => setProduct(products[1])}
                >
                  More
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    class="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </span>
                <button class="flex ml-auto text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded hidden">
                  Button
                </button>
              </div>
            </div>
            <div class="p-4 xl:w-1/4 md:w-1/2 w-full">
              <div class="h-full p-6 rounded-lg bg-gray-50 flex flex-col relative overflow-hidden  shadow-lg">
                <h2 class="text-sm tracking-widest title-font mb-1 font-medium">
                  {products[2].title}
                </h2>
                <h1 class="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200">
                  <span>₹{products[2].price}</span>
                  <span class="text-lg ml-1 font-normal text-gray-500">
                    /year
                  </span>
                </h1>
                <p class="flex items-center text-gray-600 mb-2">
                  {products[2].description}
                </p>
                <span
                  class="text-blue-500 inline-flex items-center md:mb-2 lg:mb-0"
                  onClick={() => setProduct(products[2])}
                >
                  More
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    class="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </span>
                <button class="flex ml-auto text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded hidden">
                  Button
                </button>
              </div>
            </div>
            <div class="p-4 xl:w-1/4 md:w-1/2 w-full">
              <div class="h-full p-6 rounded-lg bg-gray-50 flex flex-col relative overflow-hidden  shadow-lg">
                <h2 class="text-sm tracking-widest title-font mb-1 font-medium">
                  {products[3].title}
                </h2>
                <h1 class="text-5xl text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200">
                  <span>₹{products[3].price}</span>
                  <span class="text-lg ml-1 font-normal text-gray-500">
                    /year
                  </span>
                </h1>
                <p class="flex items-center text-gray-600 mb-2">
                  {products[3].description}
                </p>

                <span
                  class="text-blue-500 inline-flex items-center md:mb-2 lg:mb-0"
                  onClick={() => setProduct(products[3])}
                >
                  More
                  <svg
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    class="w-4 h-4 ml-2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </span>
                <button class="flex ml-auto text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded hidden">
                  Button
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}
